import { render, staticRenderFns } from "./AmazonSearch.vue?vue&type=template&id=2db9940c&scoped=true&lang=pug"
import script from "./AmazonSearch.js?vue&type=script&lang=js&external"
export * from "./AmazonSearch.js?vue&type=script&lang=js&external"
import style0 from "./AmazonSearch.sass?vue&type=style&index=0&id=2db9940c&prod&lang=sass&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db9940c",
  null
  
)

export default component.exports