<template lang="pug">
div
    div.weeklyContainer
        div.flex.justify-start.w-card.my-4.text-primary_brand.font-medium(style="padding-bottom: 13px")
            div(class="2xl:text-base xl:text-sm")
                div(class="text-sm ml-1 mb-1 text-[#1e1e1e]") Date 
                date-range-picker.text-center(v-model="dateRange" 
                opens='right'
                :autoApply="true"
                :singleDatePicker="true"
                :locale-data="{format:'dd mmm yyyy'}"
                :ranges="false"
                @update="updateComponent"
                )
        div.w-card.mb-3(v-for="filter of filters")
            div.text-primary_brand.ml-2.my-2.capitalize(class="2xl:text-xl xl:text-base font-semibold")(v-if="filter==='usertype'") User Type
            div.text-primary_brand.ml-2.my-2.capitalize(class="2xl:text-xl xl:text-base font-semibold")(v-if="filter==='srcmedium'") Source Medium
            div.text-primary_brand.ml-2.my-2.capitalize(class="2xl:text-xl xl:text-base font-semibold")(v-if="(filter !== 'usertype' && filter !=='srcmedium')") {{ filter }}
            <WeeklySummaryTable :date="dateRange.endDate" :filter="filter" :key="filter + componentRender"/>            


</template>                 
<script>
import moment from 'moment';
import WeeklySummaryTable from './WeeklySummaryTable.vue';
export default {
    name: 'WeeklySummary',
    components: { WeeklySummaryTable },
    data() {
        return {
            dateRange: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            filters: ["city", "products", "usertype", "age", "srcmedium"],
            componentRender: 0
        }
    },
    mounted() {
    },
    methods: {
        updateComponent(){
            this.componentRender += 1;
        }

    },
}
</script>
<style src="./WeeklySummary.sass" lang="sass" scoped />