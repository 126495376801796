import axios from 'axios'

export default {
    name: 'Header',
    data() {
        return {
            userData: '',
            isDropdownOpen: false,
            inputSearch: '',
            currentUserName: ""
        }
    },
    created() {
        window.addEventListener("click", this.close)
    },
    computed: {
        currentClient() {
            return this.$store.state.store.userData.current
        },
        floatingNav() {
            if (window.screen.width < 1024) {
                return true;
            } else return;
        }
    },
    mounted() {
        if (localStorage.userData) {
            this.userData = JSON.parse(localStorage.userData)
            this.currentUserName = this.userData.data.username
        }
        this.$nextTick(() => {
            if (localStorage.selectedMenu) {
                this.$store.state.store.menu[localStorage.selectedMenu].isExpanded = true;
            } 
            // else {
            // console.log('here');
            // console.log(this.$store.state.menu);
            // this.$store.state.menu[0].isExpanded = true;
            // }
        })
    },
    beforeDestroy() {
        window.removeEventListener("click", this.close)
    },
    methods: {
        openDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen
        },
        openUserControl() {
            if (this.floatingNav) {
                this.openDropdown();
            } return;
        },
        close(event) {
            if (!this.$el.contains(event.target)) {
                //prevent closing the box when clear search button is clicked
                if (event.target == this.$refs.clrBtn) return;
                else this.isDropdownOpen = false
            }
        },
        scrollToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        clearSearchBox() {
            this.inputSearch = ''
        },
        changeClients(id) {
            let url = "/api/users/switch_clients/" + id + "/"
            axios.post(url).then((res) => {
                console.log('success')
                axios.defaults.headers.common['token'] = res.data.data.token
                localStorage.userData = JSON.stringify(res.data)
                localStorage.removeItem('selectedMenu')
                this.$nextTick(() => {
                    this.$store.dispatch('store/setUser')
                    this.$store.dispatch('store/setMenu')
                    window.location = this.$store.state.store.menu[0].submenuItems[0].link;
                })
            }).catch(err => console.log(err))
        },
        logout() {
            let url = "/api/logout/"
            axios.get(url).then((Response) => {
                const resData = Response.data

            }).catch((err) => {
                console.log(err)
            });
            this.$store.dispatch("store/unsetMenu")
            localStorage.removeItem('selectedMenu')
            this.$router.push("/login");

        },
    }
}


