import Loader from "@/components/Loader/Loader.vue";
import moment from "moment";
import axios from "axios";
import Toast from "@/components/Toast/Toast.vue";


export default {
    name: "Alert",
    components: {
        Loader,
        Toast,
    },
    data() {
        return {
            categoryWiseTableData: "",
            rulesTableData: [],
            rulesTableHeader: [],
            intervalSelected: "1",
            ruleId: [],
            runTimes: [],
            rule_id: "",
            tempEmail: "",
            emailError: false,
            metrics: [],
            headersList: [],
            campaignRules: [],
            postCampaign: [],
            dropdownOptions2: [
                "Greater Than",
                "Greater Equal",
                "Equal",
                "Less Equal",
                "Less Than",
            ],
            ruleModal: undefined,
            failedCampModal: "",
            failedCampModalTitle: "",
            dateRange: {
                startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            modalDateRange: {
                startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            intervalOptions: ["Daily", "Weekly"],
            LastDate: [
                2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                22, 23, 24, 25, 26, 27, 28, 29, 30,
            ],
            LastDay: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
            ],
            dateRangeInterval: [
                {
                    key: 0,
                    label: "Today"
                },
                {
                    key: 1,
                    label: "Days"
                }
            ],
            dayInterval: [
                {
                    key: "daily",
                    label: "Daily"
                },
                {
                    key: "weekly",
                    label: "Weekly"
                },
                {
                    key: "monthly",
                    label: "Monthly"
                }
            ],
            rules: [
                {
                    rule_name: "",
                    conditions: [
                        {
                            metric: "",
                            operation: "",
                            value: null,
                        },
                    ],
                },
            ],
            alertDateRange: {
                startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            alertCampReports: {
                headers: [],
                data: [],
            },
            camp_rules: [
                {
                    rule_id: "",
                    campaign_id: "",
                    campaign_name: "",
                    campaign_type: "",
                    radioSelcted: "",
                    selectedLastDate: "",
                    selectedLastDay: "",
                    selectedLastMonth: "",
                    interval: "",
                    time: "",
                    alertName: "",
                    email: [],
                },
            ],
            campaign: [],
            campaignType: [],
            failedCampaignReports: {
                headers: [],
                data: [],
            },
            // currentActive: 1,
            // circles: Array(2).fill(null),
        }
    },
    computed: {
        progressWidth() {
            return ((this.currentActive - 1) / (this.circles.length - 1)) * 100 + '%';
        },
    },
    created() {
        const today = new Date();
        const yesterday = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 1
        );
        const monthStartDate = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            today.getDate() + 0
        );
        const thisMonthStartDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
        );
        const thisMonthEndDate = new Date();
        const lastMonthStartDate = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
        );
        const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        const thisYearStartDate = new Date(today.getFullYear(), 0, 1);
        const thisYearEndDate = new Date(today.getFullYear(), 11, 31);
        const lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1);
        const lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31);
        const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
        const quarterStartDate = new Date(
            today.getFullYear(),
            quarterStartMonth,
            1
        );

        // Calculate the first day of the current week (Sunday)
        const firstDayOfCurrentWeek = new Date(today);
        firstDayOfCurrentWeek.setDate(today.getDate() - today.getDay());

        // Calculate the last day of the previous week (Saturday)
        const lastDayOfLastWeek = new Date(firstDayOfCurrentWeek);
        lastDayOfLastWeek.setDate(firstDayOfCurrentWeek.getDate() - 1);

        // Calculate the first day of last week (Sunday)
        const firstDayOfLastWeek = new Date(lastDayOfLastWeek);
        firstDayOfLastWeek.setDate(lastDayOfLastWeek.getDate() - 6);

        this.customRanges = {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            Month: [monthStartDate, thisMonthEndDate],
            "Last Week": [firstDayOfLastWeek, lastDayOfLastWeek],
            "Last month": [lastMonthStartDate, lastMonthEndDate],
            "Last Year": [lastYearStartDate, lastYearEndDate],
            MTD: [thisMonthStartDate, today],
            QTD: [quarterStartDate, today],
            YTD: [thisYearStartDate, today],
        };
    },
    mounted() {
        // if (localStorage.campStatus) localStorage.removeItem("campStatus");
        // if (localStorage.selectedCampaign)
        //     localStorage.removeItem("selectedCampaign");
        // if (localStorage.dateRange) localStorage.removeItem("dateRange");
        // this.getCampaignTableData("");
        this.initializeModal();
        this.getAlertRules();
        this.getCampaignRule();
        this.getUpdatedAMSRefreshData();
        this.checkStatus();
        this.getAlertReports();

    },
    methods: {
        // nextStep() {
        //     if (this.currentActive < this.circles.length) {
        //         this.currentActive++;
        //     }
        // },
        // prevStep() {
        //     if (this.currentActive > 1) {
        //         this.currentActive--;
        //     }
        // },
        addButton() {
            this.ruleModal.show();
            this.getAlertRules();
        },
        addCampButton() {
            this.campModal.show();
            this.getCampaignRule();
            // this.getCampaign();
        },
        addItem(index) {
            this.rules[index].conditions.push({
                metric: "",
                operation: "",
                value: null,
            });
        },
        removeItem(ruleIndex, conditionIndex) {
            this.rules[ruleIndex].conditions.splice(conditionIndex, 1);
        },

        addRules() {
            this.rules.push({
                rule_name: "",
                conditions: [
                    {
                        metric: "",
                        operation: "",
                        value: null,
                    },
                ],
            });
        },
        // addRuleButton() {
        //     this.campModal.hide();
        //     this.ruleModal.show();
        // },
        handleBack() {
            this.$router.push("ams")
        },
        validEmail(email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        formatNumber(num) {
            if (isNaN(num)) return num;
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2;
        },
        formateDateRange(timestamp) {
            return moment(timestamp).format("lll");
        },
        multipleEmail(campIndex) {
            if (!this.validEmail(this.tempEmail)) {
                this.emailError = true;
                return false;
            }
            this.emailError = false;
            this.camp_rules[campIndex].email.push(this.tempEmail);
            this.tempEmail = "";
        },
        removeMultipleEmail(campIndex, emailIndex) {
            this.camp_rules[campIndex].email.splice(emailIndex, 1);
        },
        hideRuleModal() {
            this.ruleModal.hide();
        },
        hideCampModal() {
            this.campModal.hide();
        },
        closeRuleModal() {
            this.ruleModal.hide();
        },
        checkStatus() {
            this.intervalID = setInterval(this.getUpdatedAMSRefreshData, 9000);
        },
        initializeModal() {
            const $targetRule = document.getElementById("rule-modal");
            const ruleOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
            };
            const $targetCamp = document.getElementById("camp-modal");
            const campOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
            };
            const $targetFailedCamp = document.getElementById("failedCamp-modal");
            const failedCampOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onClose: () => {
                    this.failedCampModalTitle = "";
                    this.failedCampaignReports.headers = [];
                    this.failedCampaignReports.data = [];
                },
            };
            this.ruleModal = new Modal($targetRule, ruleOptions);
            this.campModal = new Modal($targetCamp, campOptions);
            this.failedCampModal = new Modal($targetFailedCamp, failedCampOptions);

        },
        processTable(id, sortCol = 0) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            var clear = require("@/assets/clear.png");
            $(id).DataTable({
                searching: true,
                info: true,
                sorting: [[sortCol, "desc"]],
                initComplete: function () {
                    $(id).wrap(
                        "<div style='overflow:auto; width:100%;position:relative;'></div>"
                    );
                },
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_",
                },
            });

            $(id + "_filter > label").append(`
          <div class='icons_wrapper' style='position: relative;'>
           <div class='search_icon'>
              <img src='${search}' />
            </div>
            
          <div class='close_icon'>
              <img src='${clear}' />
            </div>
          </div>
        `);

            $(id + "_filter .search_icon").css({
                position: "relative",
            });

            $(id + "_filter .close_icon").css({
                width: "20px",
                position: "absolute",
                cursor: "pointer",
                marginTop: "4px",
                top: 0,
                visibility: "hidden",
            });

            function adjustCloseIconPosition() {
                const screenWidth = window.innerWidth;
                if (screenWidth <= 1536) {
                    $(id + "_filter .close_icon").css({
                        right: "180px",
                    });
                } else {
                    $(id + "_filter .close_icon").css({
                        right: "222px",
                    });
                }
            }

            adjustCloseIconPosition();
            $(window).resize(adjustCloseIconPosition);

            $(id + "_filter input").on("input", function () {
                if ($(this).val().length > 0) {
                    $(id + "_filter .close_icon").css("visibility", "visible");
                } else {
                    $(id + "_filter .close_icon").css("visibility", "hidden");
                }
            });

            $(id + "_filter .close_icon").on("click", function () {
                $(id + "_filter input").val("");
                $(id).DataTable().search("").draw();
                $(id + "_filter .close_icon").css("visibility", "hidden");
            });
        },
        getAlertRules() {
            this.dataLoader = true;
            this.rulesTableData = "";
            let url = "/api/amazon/alert/rules";
            axios
                .get(url)
                .then((res) => {
                    const resData = res.data;
                    this.metrics = resData.data.metrices;
                    this.rulesTableHeader = resData.data.headers;
                    this.rulesTableData = resData.data.dashboard_data;
                    this.runTimes = resData.data.run_time_options;
                    this.rulesTableHeader.push({ key: "action", label: "Action" });
                    if (resData.data.rules.length)
                        this.campaignRules = resData.data.rules;
                    this.$nextTick(() => {
                        this.processTable("#ruleTable");
                    });
                    this.dataLoader = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.dataLoader = false;
                });
        },
        refrencePageData() {
            this.dataLoader1 = true;
            let url = "/api/amazon/RefreshAmsData";
            axios
                .post(url, {})
                .then((res) => {
                    this.dataLoader1 = false;
                    this.getUpdatedAMSRefreshData();
                    this.checkStatus();
                })
                .catch((err) => {
                    console.log(err);

                    this.dataLoader1 = false;
                });
        },
        deleteAlertRules(deleteRuleId) {
            this.dataLoader = true;
            let url = "/api/amazon/alert/rule_delete";
            let payload = {
                rule_id: deleteRuleId,
            };
            if (confirm("Are you sure you want to delete?")) {
                axios
                    .post(url, payload)
                    .then((res) => {
                        this.getAlertRules();
                        this.dataLoader = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.dataLoader = false;
                    });
            } else {
                this.dataLoader = false;
                return false;
            }
        },
        getCampaignRule() {
            this.dataLoader1 = true;
            this.campaignType = [];
            this.campaignTableData = [];
            this.campaignTableHeader = [];
            let url = "/api/amazon/alert/campaign_rule";
            axios
                .get(url)
                .then((res) => {
                    const resData = res.data;
                    this.dataLoader1 = false;
                    this.campaignType = resData.data.campaign_types;
                    if (this.campaignType.length != 0) {
                        this.campaignType.unshift({
                            key: "ALL",
                            label: "all",
                        });
                    }

                    this.campaignTableHeader = resData.data.headers;
                    this.campaignTableData = resData.data.campaign_rules;
                    this.$nextTick(() => {
                        this.processTable("#campaignRuleTable");
                    });
                    this.dataLoader1 = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.dataLoader1 = false;
                });
        },
        getUpdatedAMSRefreshData() {
            let intervalTempId = this.intervalID;
            this.viewUpdatedAMSRefreshData = "";
            let url = "/api/amazon/GetRefreshAmsDataStatus";
            axios
                .get(url)
                .then((res) => {
                    this.viewUpdatedAMSRefreshData = res.data.data;
                    if (
                        this.viewUpdatedAMSRefreshData.refresh_status === "failed" ||
                        this.viewUpdatedAMSRefreshData.refresh_status === "completed"
                    ) {
                        clearInterval(intervalTempId);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deleteCampaignRules(deleteCampId) {
            this.dataLoader = true;
            let url = "/api/amazon/alert/camp_rule_delete";
            let payload = {
                alert_id: deleteCampId,
            };
            if (confirm("Are you sure you want to delete?")) {
                axios.post(url, payload).then((res) => {
                    // this.campaignTableData = this.campaignTableData.filter(
                    //   (row) => row.alert_id !== deleteCampId
                    // );
                    this.getCampaignRule();
                    location.reload();
                    this.dataLoader = false;
                });
            } else {
                this.dataLoader = false;
                return false;
            }
        },
        getAlertReports() {
            console.log("Alert reports");
            this.dataLoader = true;
            $("#reportsTable").DataTable().destroy();
            this.alertCampReports.headers = [];
            this.alertCampReports.data = [];
            const url = "/api/amazon/alert/name_list";
            const payload = {
                start_date: moment(this.alertDateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.alertDateRange.endDate).format("YYYY-MM-DD"),
            };

            axios
                .get(url, { params: payload })
                .then((res) => {
                    this.alertCampReports.headers = res.data.data.headers;
                    this.alertCampReports.data = res.data.data.report_names;
                    this.$nextTick(() => {
                        this.processTable("#reportsTable");
                    });
                    this.dataLoader = false;
                })
                .catch((err) => {
                    this.dataLoader = false;
                    console.log(`Error in getting alert reports::${err}`);
                });
        },
        getFailedCampaigns(report) {
            this.dataLoader = true;

            const url = "/api/amazon/alert/reports";
            const payload = {
                start_date: moment(this.alertDateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.alertDateRange.endDate).format("YYYY-MM-DD"),
                report_name: report,
            };
            this.failedCampModalTitle = `Failed Campaigns - ${report}`;

            axios
                .get(url, { params: payload })
                .then((res) => {
                    this.failedCampaignReports.headers = res.data.data.headers;
                    this.failedCampaignReports.data = res.data.data.data;
                    this.dataLoader = false;
                    this.failedCampModal.show();
                    this.$nextTick(() => {
                        this.processTable("#failedCampTable", 0, "300px");
                    });
                })
                .catch((err) => {
                    this.dataLoader = false;
                    console.log(`Error in getting failed campaigns::${err}`);
                });
        },
        postAlertRules() {
            this.dataLoader = true;
            let url = "/api/amazon/alert/rules";

            if (!this.rules[0].rule_name) {
                this.$toast.error("Rule name can't be empty", {
                    position: "bottom-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                return;
            }

            let payload = {
                rules: [],
            };

            if (this.rules[0].conditions.length > 1) {
                this.rules[0].conditions.forEach((condition, index) => {
                    if (!condition.metric || !condition.operation || !condition.value) {
                        this.rules[0].conditions.splice(index, 1);
                    }
                });
            } else {
                if (
                    !this.rules[0].conditions[0].metric ||
                    !this.rules[0].conditions[0].operation ||
                    !this.rules[0].conditions[0].value
                ) {
                    this.$toast.error("Rule properties can't be empty", {
                        position: "bottom-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                    return;
                }
            }

            payload["rules"] = this.rules;
            axios
                .post(url, payload)
                .then((res) => {
                    // alert("Rule Added Successfully");
                    this.dataLoader = false;
                    this.rules = [
                        {
                            rule_name: "",
                            conditions: [
                                {
                                    metric: "",
                                    operation: "",
                                    value: null,
                                },
                            ],
                        },
                    ];
                    this.$toast.success("Added Successfully", {
                        position: "bottom-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                    this.getAlertRules();
                    this.closeRuleModal();
                })
                .catch((err) => {
                    console.log(err);
                    this.dataLoader = false;
                });
        },
        getCampaign(type) {
            this.dataLoader = true;
            let key = [];
            this.campaign = [];
            if (type.length) {
                if (type[0].label == "all") {
                    for (let data of this.campaignType) {
                        if (data.label != "all") key.push(data.label);
                    }
                } else {
                    for (let data of type) {
                        key.push(data.label);
                    }
                }
            } else {
                this.dataLoader = false;
            }

            let url = "/api/amazon/alert/campaigns?ad_type=" + key.toString();
            axios
                .get(url)
                .then((res) => {
                    const resData = res.data;
                    this.dataLoader = false;
                    this.campaign = resData.data;
                    if (this.campaign) {
                        this.campaign.unshift({
                            camp_id: "all",
                            camp_name: "ALL",
                        });
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                    this.dataLoader = false;
                });
        },
        postCampaignRule() {
            this.postCampaign = [];
            this.dataLoader = true;
            for (let Element of this.camp_rules) {
                let temp = {
                    rule_id: Element.rule_id.map((value) => {
                        return value.rule_id;
                    }),
                    campaign_id:
                        Element.camp_id[0].camp_id == "all"
                            ? []
                            : Element.camp_id.map((value) => {
                                return value.camp_id;
                            }),
                    campaign_name:
                        Element.camp_id[0].camp_name == "ALL"
                            ? []
                            : Element.camp_id.map((value) => {
                                return value.camp_name;
                            }),
                    campaign_type:
                        Element.campaign_type[0].label == "all"
                            ? []
                            : Element.campaign_type.map((value) => {
                                return value.label;
                            }),
                    interval: Element.intervalSelected.key,
                    run_at: Element.time,
                    alert_name: Element.alertName,
                    email: Element.email,
                    data_duration:
                        Element.radioSelcted.key == 0 ? 0 : Element.selectedLastDate,
                };
                if (this.tempEmail) {
                    if (!this.validEmail(this.tempEmail)) {
                        this.emailError = true;
                        this.dataLoader = false;
                        return false;
                    }
                    Element.email.push(this.tempEmail);
                }

                if (temp.interval == "weekly") {
                    Object.assign(temp, { day_of_week: Element.selectedLastDay });
                }
                if (temp.interval == "monthly") {
                    Object.assign(temp, { day_of_month: Element.selectedLastMonth });
                }
                this.postCampaign.push(temp);
            }
            let url = "/api/amazon/alert/campaign_rule";

            let payload = {
                camp_rules: this.postCampaign,
            };

            axios
                .post(url, payload)
                .then((res) => {
                    this.camp_rules = [
                        {
                            rule_id: "",
                            campaign_id: "",
                            campaign_name: "",
                            campaign_type: "",
                            radioSelcted: "",
                            selectedLastDate: "",
                            selectedLastDay: "",
                            selectedLastMonth: "",
                            interval: "",
                            time: "",
                            alertName: "",
                            email: "",
                        },
                    ];
                    this.$toast.success("Rule Added Successfully", {
                        position: "bottom-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });
                    this.hideCampModal();
                    this.getCampaignRule();
                    location.reload();
                    this.dataLoader = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.dataLoader = false;
                });
        },
    },
}
