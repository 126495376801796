import axios from "axios";
export default{
    name : 'Register',

    data(){
        return{
            username:'',
            email:'',
            mobile:'',
            password:'',
            cPassword:'',
            usernameError:false,
            emailError:false,
            mobileError:false,
            confirmPasswordError:false,
            alertPopup:undefined,


        }


        
    },
    mounted() {
        
    },

    methods: {
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validNumber(number){
            var no=/^[0-9]{10}$/;
            return no.test(number);
        },
        submit(){
            let url= "/api/users/"
            let payload={
                "username":this.username,
                "email":this.email,
                "mobile":this.mobile,
                "password":this.password,
            };
            if(this.username=="")
            this.usernameError=true
            if (!this.validEmail(this.email) || !this.email) {
                this.emailError=true
            }
            if (!this.validNumber(this.mobile) || !this.mobile) {
                this.mobileError=true
            }
            if(this.password!==this.cPassword){
                this.confirmPasswordError=true
            }
            if(this.usernameError == false && this.emailError==false && this.confirmPasswordError==false && this.mobileError==false)
            {
                axios.post(url,payload).then((Response) => {
                    const resData=Response.data
                    alert(resData.summary);
                }).catch(err => console.log(err))
                // console.log(payload);
            }      
        }    
    },
}