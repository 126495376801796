import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import moment from 'moment'

const store = {
    namespaced: true,
    state: {
        filter:{
            startDate: moment().subtract(1, 'week').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        },
        selectedFBCreativeMetric: [
            {
              isAvailable: true,
              label: 'Ad Name',
              key: 'Ad Name',
            },
            {
              isAvailable: true,
              label: 'Impressions',
              key: 'Impressions',
            },
            {
              isAvailable: true,
              label: 'Spend',
              key: 'Spend',
            },
            {
              isAvailable: true,
              label: 'Meta Revenue',
              key: 'Meta Revenue',
            },
            {
              isAvailable: true,
              label: 'Reach',
              key: 'Reach',
            },
            {
              isAvailable: true,
              label: 'CPC',
              key: 'CPC',
            }
          ],          
        userData: {
            id: "",
            username: "",
            has_ecomm: false,
            year: '01-01',
            clients: [],
            current: ""
        },
        menu: []
    },
    mutations: {
        SET_FB_Creative_Metric(state,selectedMetric){
            state.selectedFBCreativeMetric=selectedMetric
        },
        SET_DATE(state,dateRange){
            state.filter.startDate= dateRange.startDate
            state.filter.endDate = dateRange.endDate
        },
        UNSET_MENU(state) {
            localStorage.removeItem('userData')
            state.menu = []
            state.userData = { id: '', username: '' }
        },
        SET_USER(state) {
            if(localStorage.userData != undefined) {
                state.userData = {
                    username: JSON.parse(localStorage.userData).data.username,
                    id: JSON.parse(localStorage.userData).data.user_id,
                    started: moment(JSON.parse(localStorage.userData).started),
                    clients: JSON.parse(localStorage.userData).clients.clients,
                    current: JSON.parse(localStorage.userData).clients.current,
                    internal: JSON.parse(localStorage.userData).internal,
                    meeting: JSON.parse(localStorage.userData).meeting,
                    monitoring: JSON.parse(localStorage.userData).monitoring,
                    fb_access: JSON.parse(localStorage.userData).fb_access,
                    screen_access: JSON.parse(localStorage.userData).screen_access,
                    weavr_admin_access: JSON.parse(localStorage.userData).weavr_admin_access,
                    datastudio_access: JSON.parse(localStorage.userData).datastudio_access,
                    googledemo_access: JSON.parse(localStorage.userData).googledemo_access,
                }
            }
        },
        SET_MENU(state) {
            if (!state.userData.id) return;
            try {
                var usermenu = JSON.parse(localStorage.userData).menu;
            }
            catch (e) {
                usermenu = {}
            }

            if (state.userData.username === 'admin') {
                let primary = {
                    title: "Admin",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: "Users",
                            link: "/users"
                        },
                        {
                            item: "Clients",
                            link: "/clients"
                        },
                    ]
                }

                let data = {
                    title: "Data",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: "FB Data",
                            link: "/fb-data"
                        },
                        {
                            item: "DBM Data",
                            link: "/dbm-data"
                        },
                        {
                            item: "KPI Tracker",
                            link: "/kpi-tracker"
                        },
                        {
                            item: "Programmatic Data",
                            link: "/programmatic-admin"
                        },
                        {
                            item: "Pilot Report",
                            link: "/pilot-report"
                        },
                        {
                            item: "Media Data",
                            link: "/media-data"
                        },
                        {
                            item: "Custom Data",
                            link: "/custom-data"
                        }]
                }
                let mapping = {
                    title: "Mapping",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: "Adword Mapping",
                            link: "/adword-mapping"
                        },
                        {
                            item: "Facebook Mapping",
                            link: "/fb-mapping"
                        },
                        {
                            item: "GA Mapping",
                            link: "/ga-mapping"
                        },
                        {
                            item: "LinkedIn Mapping",
                            link: "/linkedin-mapping"
                        }]
                }
                let tools = {
                    title: "Tools",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: "Report Download",
                            link: "/report-download"
                        },
                        {
                            item: "Ad Screenshot",
                            link: "/ad-screenshot"
                        }
                    ]
                }

                var menu = [primary, data, mapping, tools];
            }
            else if (state.userData.username !== 'admin') {
                // state.userData.year = moment(usermenu.financial_year, 'MM-DD')

                let Summary = {
                    title: "Summary",
                    isExpanded: false,
                    submenuItems: []
                }

                if (state.userData.current === "Merino Domestic") {
                    if (usermenu.cmo_summary) {
                        Summary.submenuItems.push(
                            {
                                item: "CMO Dashboard",
                                link: "/cmo-dashboard"
                            }
                        )
                    }
                    if (usermenu.kpi_summary) {
                        Summary.submenuItems.push(
                            {
                                item: "KPI Summary",
                                link: "/kpi-summary"
                            }
                        )
                    }
                } else {
                    Summary.submenuItems.push(
                        {
                            item: "Business Summary",
                            link: "/business-summary"
                        }
                    )
                }

                var menu = [Summary];

                if (usermenu.GA == false ) {
                    menu = [];
                }
                if(state.userData.current === "Merino Domestic"){
                    menu = [Summary];
                }
                // if(state.userData.current ==='FanCode' || state.userData.current=='Boat'){
                //     menu = []
                // }
                let Facebook = {
                    title: "Facebook",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: "Facebook Summary",
                            link: "/facebook-summary"
                        },
                        
                    ]
                }
                if(usermenu.Creative_Summary){
                    Facebook.submenuItems.push({
                        item: "Creative Tracking",
                        link: "/all-creatives"
                    })
                }
                // if(usermenu.Creative_Summary) {
                //     Facebook.submenuItems.push({
                //         item: "Facebook Creatives",
                //         link: "/facebook-creatives"
                //     },)
                // }
                let Ads = {
                    title: "Google Ads",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: 'Summary',
                            link: '/summary'
                        },
                        {
                            item: 'Keyword Data',
                            link: '/keyword-data'
                        },
                        {
                            item: 'Campaign Summary',
                            link: '/campaign-summary'
                        },
                        {
                            item: 'Adword Summary',
                            link: '/adword-summary'
                        },
                        {
                            item: 'Device Summary',
                            link: '/device-summary'
                        }
                    ]
                }
                if(usermenu.Search_Volume){
                    Ads.submenuItems.push( {
                        item: 'Search Volume',
                        link: '/search-volume-summary'
                    },)
                }
                let Analytics = {
                    title: "Google Analytics",
                    isExpanded: false,
                    submenuItems: []
                }

                let Reports = {
                    title: "Reports",
                    isExpanded: false,
                    submenuItems: []
                }
                state.userData.has_ecomm = usermenu.Ecomm;
                state.userData.week_start = usermenu.week_start;
                state.userData.week_end = usermenu.week_end;
                state.userData.has_video = usermenu.video;
                state.userData.has_ga = usermenu.GA;
                state.userData.has_kama = usermenu.kama;
                state.userData.fb_utm = usermenu.FBUTM;
                state.userData.programmatic = usermenu.programmatic;

                if (usermenu.programmatic) {
                    Reports.submenuItems.push(
                        {
                            item: 'Programmatic Report',
                            link: '/programmatic'
                        }
                    )
                }


                if (usermenu.Ecomm) {
                    Analytics.submenuItems = Analytics.submenuItems.concat([
                        {
                            item: 'Analytics Summary',
                            link: '/analytics-summary'
                        },


                    ]);
                    if (!usermenu.GA4) {
                        Analytics.submenuItems.push({
                            item: 'Weekly Summary',
                            link: '/weekly-summary'
                        },
                            {
                                item: 'Heatmap',
                                link: '/heatmap'
                            })
                    }
                } else {
                    Analytics.submenuItems = Analytics.submenuItems.concat([
                        {
                            item: 'Analytics Summary',
                            link: '/analytics-summary'
                        },
                    ])
                    if (!usermenu.GA4) {
                        Analytics.submenuItems.push({
                            item: 'Heatmap',
                            link: '/heatmap'
                        })
                    }
                }
                // if (usermenu.ADWORD) {
                //     Reports.submenuItems.push(
                //         {
                //             item: 'Campaign Report',
                //             link: '/campaign-report'
                //         },
                //     )
                // }

                if (usermenu.asics && !state.userData.googledemo_access) {
                    Reports.submenuItems.push(
                        {
                            item: 'Media Report',
                            link: '/media-report'
                        })
                }

                if (usermenu.kama) {
                    Ads.submenuItems.push(
                        {
                            item: 'Account Report',
                            link: '/account-report'
                        },
                    )
                }

                Reports.submenuItems.push(
                    {
                        item: 'Account Report',
                        link: '/account-report'
                    })

                let Amazon = {
                    title: "Amazon",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: 'Summary',
                            link: '/amazon-search'
                        },
                        // {
                        //     item: 'Amazon Budget Rule',
                        //     link: '/amazon-budget-rule'
                        // },
                        // {
                        //     item: 'Amazon Silver',
                        //     link: '/amazon-silver'
                        // }
                        // ,
                        {
                            item: 'AMS',
                            link: '/ams'
                        }
                      

                    ]
                }
                if(state.userData.current==='W for Women'){
                    Amazon.submenuItems.push({
                        item: 'Adset Summary',
                        link: '/adset-summary'
                    })
                }
                if (usermenu.Amazon_Display) {
                    Amazon.submenuItems.push(
                        {
                            item: 'Amazon Display',
                            link: '/amazon-display'
                        },
                    )
                }
                if (usermenu.amz_tragated_report) {
                    Amazon.submenuItems.push(
                        {
                            item: 'Performance Summary',
                            link: '/performance-summary'
                        },
                    )
                }
                if (usermenu.AMD_Report) {
                    Amazon.submenuItems.push(
                        {
                            item: 'Amazon Report',
                            link: '/amazon-report'
                        }
                    )
                }
                if (state.userData.current === "RR Kabel" || state.userData.current ===
                    "AMD" || state.userData.current == "Bosch Power Tools") {
                    Amazon.submenuItems.push(
                        {
                            item: 'Account Summary',
                            link: '/amazon-account-summary'
                        }
                    )
                }
                if (state.userData.current === "Tasva") {
                    Reports.submenuItems.push(
                        {
                            item: 'Account Summary',
                            link: '/client-report'
                        }
                    )
                }

                let Flipkart = {
                    title: "Flipkart",
                    isExpanded: false,
                    submenuItems: []
                }

                if (usermenu.Flipkart_Summary) {
                    Flipkart.submenuItems.push(
                        {
                            item: 'Flipkart Summary',
                            link: '/flipkart-summary'
                        },
                    )
                }

                if (usermenu.Flipkart_Search) {
                    Flipkart.submenuItems.push(
                        {
                            item: 'Flipkart Search',
                            link: '/flipkart-search'
                        },
                    )
                }
                if (usermenu.Flipkart_Display) {
                    Flipkart.submenuItems.push(
                        {
                            item: 'Flipkart Display',
                            link: '/flipkart-display'
                        },
                    )
                }
                if (usermenu.AMD_Report) {
                    Flipkart.submenuItems.push(
                        {
                            item: 'Flipkart Report',
                            link: '/flipkart-report'
                        }
                    )
                }

                let Myntra = {
                    title: "Myntra",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: 'Summary',
                            link: '/myntra-summary'
                        }
                    ]
                }
                let Snapchat = {
                    title: "Snapchat",
                    isExpanded: false,
                    submenuItems: [
                        {
                            item: 'Summary',
                            link: '/snapchat-summary'
                        }
                    ]
                }
                let GenAI = {
                    title: "Gen AI",
                    isExpanded: false,
                    submenuItems: [
                      {
                        item: "WeavrWise",
                        link: "/gen-ai",
                      },
                    ],
                };
                let Shopify=''
                if(state.userData.current==='Demo Account'){
                    menu=[]
                    Amazon = {
                        title: "Amazon",
                        isExpanded: false,
                        submenuItems: [{
                            item:'Summary',
                            link:'/summary/amazon'
                    }]},
                    Facebook = {
                        title: "Facebook",
                        isExpanded: false,
                        submenuItems: [{
                            item:'Summary',
                            link:'/summary/fb'
                    }]},
                    Analytics = {
                        title:"Google Analytics",
                        isExpanded: false,
                        submenuItems: [{
                            item:'Summary',
                            link:'/summary/ga4'
                    }]},

                    Ads = {
                        title:  "Google Ads",
                        isExpanded: false,
                        submenuItems: [{
                            item:'Summary',
                            link:'/summary/adword'
                    }]},

                    Shopify = {
                        title:  "Shopify",
                        isExpanded: false,
                        submenuItems: [{
                            item:'Summary',
                            link:'/summary/Shopify'
                    }]}
        
                }
               
                if(state.userData.current==='Account 1') menu.push(GenAI)
                if(usermenu.Snapchat) menu.push(Snapchat)

                if (usermenu.ADWORD) menu.push(Ads)
                if (usermenu.GA) menu.push(Analytics);
                //      if(usermenu.SENTIMENT) menu.push("Sentiment")
                if (usermenu.FB) menu.push(Facebook);
                // if (usermenu.Linkedin) menu.push(Linkedin)
                if (usermenu.Amazon) menu.push(Amazon)
                if (Flipkart.submenuItems.length) menu.push(Flipkart)
                if (usermenu.Myntra) menu.push(Myntra)
                if (usermenu.Shopify) menu.push(Shopify)
                if(state.userData.current === "Merino Domestic"){
                    Reports = ''
                }
                else if(state.userData.current === "Demo Account"){
                    Reports = ''
                }
                else if (Reports.submenuItems.length){
                    menu.push(Reports)
                }

                // if (state.user.meeting)
                //     menu.push(meeting);
                // if (state.user.monitoring)
                //     menu.push(monitoring);
                // }
               
                
            }
            // for demo 
           
            // if (state.user.googledemo_access) {
            //     menu = []
            //     menu.push({ title: "Summary", name: "Summary", googledemo: true })
            //     menu.push({ title: "Campaign Summary", name: "adwords.byCampaign", googledemo: true })
            //     menu.push({ title: "Adword Summary", name: "adwords.Detailed", googledemo: true })
            //     menu.push({ title: "Device Summary", name: "adwords.devicesummary", googledemo: true })
            // }
            state.menu = menu;
            // setTimeout(function () {
            //     $('.main-menu .ui.dropdown.item').click(function (e) {
            //         e.stopPropagation();
            //         $(this).siblings().children('.menu').hide();
            //         $(this).children('.menu').toggle();
            //     })
            //     $('body').click(function () {
            //         $('.main-menu .ui.dropdown.item').children('.menu').hide();
            //     })
            //     $('body').click(function () {
            //         $('.main-menu .ui.dropdown.item').children('.menu').hide();
            //     })

            // }, 1000);
        },
        SET_EXPANSION(state, menuTitle) {
            state.menu.map(item => {
                if (item.title === menuTitle) {
                    item.isExpanded = !item.isExpanded
                }
            })
        }
    },
    actions: {
        setFBCreativeMetric({commit},selectedMetric){
            commit("SET_FB_Creative_Metric",selectedMetric)
        },
        setDate({commit},dateRange){
            commit('SET_DATE',dateRange)
        },
        setMenu({ commit }) {
            commit('SET_MENU')
        },
        unsetMenu({ commit }) {
            commit('UNSET_MENU')

        },
        setUser({ commit }) {
            commit('SET_USER')
        },
        toggleExpansion({ commit }, menuTitle) {
            commit('SET_EXPANSION', menuTitle)
        }
    }
}

export default store;