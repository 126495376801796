import { render, staticRenderFns } from "./BrandWiseSearchVolume.vue?vue&type=template&id=c7185302&lang=pug"
import script from "./BrandWiseSearchVolume.js?vue&type=script&lang=js&external"
export * from "./BrandWiseSearchVolume.js?vue&type=script&lang=js&external"
import style0 from "./BrandWiseSearchVolume.vue?vue&type=style&index=0&id=c7185302&prod&scopped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports