import moment from 'moment'

const filter = {
    namespaced: true,
    state: {
       dateRange:'',
       menu: [],
       SVAPIDateRangeDate:'',
       SVSelectedDateRange:{
        startDate:'',
        endDate:'',
       }
    },

    mutations: {
        GET_SVAPIDateRangeData(state,data){
            state.SVAPIDateRangeDate=data
            state.SVSelectedDateRange.startDate=  moment(state.SVAPIDateRangeDate.end_date).startOf('month').subtract(3,"months").format("YYYY-MM-DD")
            state.SVSelectedDateRange.endDate = moment(state.SVAPIDateRangeDate.end_date).format("YYYY-MM-DD")
        },   
        SET_SELECTED_DATE(state,dateRange){
            state.SVSelectedDateRange.startDate= dateRange.startDate
            state.SVSelectedDateRange.endDate = dateRange.endDate
        }, 
    },

    actions: {
        setSVDateAPIDate({commit},data){
            commit("GET_SVAPIDateRangeData",data)
        },
        setSelectedDate({commit},dateRange){
            commit('SET_SELECTED_DATE',dateRange)
        },
    }
}

export default filter;