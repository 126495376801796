import { render, staticRenderFns } from "./KPISummary.vue?vue&type=template&id=481b6038&scoped=true&lang=pug"
import script from "./KPISummary.js?vue&type=script&lang=js&external"
export * from "./KPISummary.js?vue&type=script&lang=js&external"
import style0 from "./KPISummary.vue?vue&type=style&index=0&id=481b6038&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481b6038",
  null
  
)

export default component.exports