export default {
  name: "SuggestedPrompts",
  props: {
    handlePreDefinedPrompt: Function,
  },
  components: {},
  data() {
    return {
      preDefinedPrompts: [
        "What are my top performing campaigns this week?",
        "How have the total impressions changed over the last month?",
        "Which campaign has generated the highest sales this week?",
        "What is the highest average selling price of the first week of last month? Give the details with campaign name.",
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
