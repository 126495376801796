import Loader from "@/components/Loader/Loader.vue";
import axios from "axios";
import moment from "moment";
import lineChart from '@/components/charts/lineChart.vue'
import AdvancedFilter from "@/components/Filter/AdvancedFilter.vue";

export default {
    name: "AMSAnalytics",
    components: {
        Loader,
        lineChart,
        AdvancedFilter
    },
    data() {
        return {
            metrics: [],
            isMetricDropdown: false,
            loader: false,
            loader1: false,
            loader2: false,
            loader3: false,
            loader4: false,
            loader5: false,
            activeCampaign: true,
            globalFilter: {},
            defaultMetrices: {
                selectedSection: "Metrics",
                selectedTable: "Campaign",
                selectedMetric: "ACOS",
            },
            searchTables: ['Campaigns', 'AdProducts', 'Keywords', 'Search Terms'],
            appliedGlobalFilters: [],
            isGlobalFilterOpen: false,
            globalSearchFilter: false,
            appliedGlobalSearchFilters: [],
            selectedCampaignTypeData: [
                {
                    key: "Sponsored Products", label: "sp",
                },
                {
                    key: "Sponsored Brands", label: "sb",
                }
            ],
            campaignOptions: [
                {
                    key: "Sponsored Products", label: "sp",
                },
                {
                    key: "Sponsored Brands", label: "sb",
                },
                {
                    key: "Sponsored Display", label: "sd",
                }
            ],
            dateRange: {
                startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            customRanges: [],
            campaignNamesList: [],
            selectedCampaigns: [],
            showComparison: false,
            intervals: [
                {
                    dateRange: {
                        startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                    }
                },
                {
                    dateRange: {
                        startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                    }
                },
                {
                    dateRange: {
                        startDate: moment().subtract(64, "days").format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                    }
                }
            ],
            campaignTable: {
                header: [],
                data: [],
                subHeader: [],
                rawData: [],
                compare: false,
            },
            selectedTab: 'Campaigns',
            drilledCampaignsList: [],
            drilledKeywordsList: [],
            ASINTable: {
                header: [],
                data: [],
                subHeader: [],
                rawData: [],
                compare: false,
            },
            keywordsTable: {
                header: [],
                data: [],
                subHeader: [],
                rawData: [],
                compare: false,
            },
            searchTermsTable: {
                header: [],
                data: [],
                subHeader: [],
                rawData: [],
                compare: false,
            },
            MovingAvgGraphData: [],
            selectedMovAvgData: 'Daily',
            graphDropdown: '',
            selectedGraphOptions: 'ACOS',
            moviginAvgData: '',
            showCustomDateRange: false,
            applyComparison: false,
            showLayover: false,
            customInterval: {},
            tooltip: {
                "Interval 1": null,
                "Interval 2": null,
                "Interval 3": null,
                "Interval 4": null
            },
            metricesList: ['ACOS', 'Spends', 'Sales', 'Impressions', 'Clicks'],
            selectedMetrices: ['ACOS', 'Spends', 'Sales', 'Clicks'],
            nonCompareMetrices: [],
            selectedNonCompareMetrices: [],
            metricesChange: false,
            campaignsChange: false,
            keywordFilterMatchType: "",
            searchTermFilterMatchType: ""
        };
    },
    created() {
        const today = new Date();
        const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
        const monthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() + 0);
        const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
        const thisMonthEndDate = new Date();
        const lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        const thisYearStartDate = new Date(today.getFullYear(), 0, 1);
        const thisYearEndDate = new Date(today.getFullYear(), 11, 31);
        const lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1);
        const lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31);
        const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
        const quarterStartDate = new Date(today.getFullYear(), quarterStartMonth, 1);
        const last7DaysStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
        const last30DaysStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29);
        const last65DaysStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 64);
        const last90DaysStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 89);

        // Calculate the first day of the current week (Sunday)
        const firstDayOfCurrentWeek = new Date(today);
        firstDayOfCurrentWeek.setDate(today.getDate() - today.getDay());

        // Calculate the last day of the previous week (Saturday)
        const lastDayOfLastWeek = new Date(firstDayOfCurrentWeek);
        lastDayOfLastWeek.setDate(firstDayOfCurrentWeek.getDate() - 1);

        // Calculate the first day of last week (Sunday)
        const firstDayOfLastWeek = new Date(lastDayOfLastWeek);
        firstDayOfLastWeek.setDate(lastDayOfLastWeek.getDate() - 6);

        this.customRanges = {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            Month: [monthStartDate, thisMonthEndDate],
            "Last Week": [firstDayOfLastWeek, lastDayOfLastWeek],
            "Last month": [lastMonthStartDate, lastMonthEndDate],
            "Last Year": [lastYearStartDate, lastYearEndDate],
            MTD: [thisMonthStartDate, today],
            QTD: [quarterStartDate, today],
            YTD: [thisYearStartDate, today],
        };

        this.customInterval = {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            "Last 7 days": [last7DaysStartDate, today],
            "Last 30 days": [last30DaysStartDate, today],
            "Last 65 days": [last65DaysStartDate, today],
            "Last 90 days": [last90DaysStartDate, today],
            "Last Year": [lastYearStartDate, lastYearEndDate],
        }

        window.addEventListener("click", this.close)
    },
    mounted() {
        this.getGlobalFilter()
        this.activeCampaign = localStorage.campStatus;
        this.selectedCampaigns.push(localStorage.selectedCampaign);
        if (localStorage.dateRange) {
            this.dateRange = JSON.parse(localStorage.dateRange)
        }
        this.getUpdatedData("firstRender");
        this.generateMetricsForFilter();
    },
    beforeDestroy() {
        window.removeEventListener("click", this.close)
    },
    watch: {
        selectedMetrices(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.metricesChange = true;
            }
        },
        selectedCampaigns(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.campaignsChange = true;
            }
        },
        metrics: {
            deep: true,
            handler() {
                this.selectMetric();
            }
        }
    },
    methods: {
        selectMetric(metric) {
            metric.isAvailable = !metric.isAvailable;

            if (metric.isAvailable) {
                this.selectedNonCompareMetrices.push(metric);
            } else {
                this.selectedNonCompareMetrices = this.selectedNonCompareMetrices.filter(
                    m => m.label !== metric.label
                );
            }

            this.tableHeader = this.metrics.filter(data => data.isAvailable);
            this.$store.dispatch('setFBCreativeMetric', this.tableHeader);
            this.redrawTable();
        },
        removeSelectedMetric(idx, data) {
            const matchIndex = this.selectedNonCompareMetrices.findIndex(
                (item) => item.label === data.label
            );

            if (matchIndex !== -1) {
                this.selectedNonCompareMetrices.splice(matchIndex, 1);
            }
            this.metrics[idx].isAvailable = false
            this.redrawTable()
        },
        generateMetricsForFilter() {
            const selectedMetrics = this.selectedNonCompareMetrices.map(data => data.label);
            this.metrics = this.nonCompareMetrices.map(data => {
                return {
                    isAvailable: selectedMetrics.includes(data.label),
                    label: data.label,
                    key: data.key
                };
            });
            this.tableHeader = this.metrics.filter(data => data.isAvailable);
            this.$store.dispatch('setFBCreativeMetric', this.tableHeader);
        },
        toggleAdMetricDropdown() {
            this.isMetricDropdown = !this.isMetricDropdown;
            if (!this.isMetricDropdown) this.redrawTable();
        },
        close(event) {
            if (this.metrics.length) {
                if (!this.$refs.metricDropdownRef.contains(event.target)) {
                    this.isMetricDropdown = false
                }
            }

        },
        updateMetricsHeader() {
            switch (this.selectedTab) {
                case 'Campaigns':
                    this.metrics = this.campaignTable?.header || [];
                    break;
                case 'ASINs':
                    this.metrics = this.ASINTable?.header || [];
                    break;
                case 'Keywords':
                    this.metrics = this.keywordsTable?.header || [];
                    break;
                case 'Search Terms':
                    this.metrics = this.searchTermsTable?.header || [];
                    break;
                default:
                    this.metrics = [];
                    break;
            }
            this.metrics.forEach(metric => {
                metric.isAvailable = true;
            });

            if (this.metrics.length > 0) {
                this.$store.dispatch('setFBCreativeMetric', this.metrics);
            }
        },
        toggleGlobalFilter(stateVal) {
            if (stateVal) {
                this.isGlobalFilterOpen = stateVal;
            } else {
                this.isGlobalFilterOpen = !this.isGlobalFilterOpen;
            }
        },
        toggleGlobalSearchFilter(stateVal) {
            if (stateVal) {
                this.globalSearchFilter = stateVal;
            } else {
                this.globalSearchFilter = !this.globalSearchFilter;
            }
        },
        addGlobalSearch(table, query) {
            if (!query) {
                this.showErrorToast('Provide a query to filter table.');
                return;
            }
            this.appliedGlobalSearchFilters.push({
                table: table,
                query: query
            });

            this.applyGlobalSearchFilter();
        },
        applyGlobalSearchFilter() {
            if (this.appliedGlobalSearchFilters.length) {
                this.appliedGlobalSearchFilters.forEach(filter => {
                    console.log(filter)
                    if (filter.table === 'Campaigns') {
                        $('#campaigns_table').DataTable().column(1).search(filter.query, false, false).draw()
                    } else if (filter.table === 'AdProducts') {
                        $('#asins_table').DataTable().column(0).search(filter.query, false, false).draw()
                    } else if (filter.table === 'Keywords') {
                        $('#keywords_table').DataTable().column(2).search(filter.query, false, false).draw()
                    } else if (filter.table === 'Search Terms') {
                        $('#search_terms_table').DataTable().column(1).search(filter.query, false, false).draw()
                    }
                })
            } else {
                $('#campaigns_table').DataTable().column(1).search("", false, false).draw()
                $('#asins_table').DataTable().column(0).search("", false, false).draw()
                $('#keywords_table').DataTable().column(1).search("", false, false).draw()
                $('#search_terms_table').DataTable().column(0).search("", false, false).draw()
            }
        },
        removeGlobalSearch(idx) {
            const table = this.appliedGlobalSearchFilters[idx].table;

            if (table === 'Campaigns') {
                $('#campaigns_table').DataTable().column(1).search("", false, false).draw()
            } else if (table === 'AdProducts') {
                $('#asins_table').DataTable().column(0).search("", false, false).draw()
            } else if (table === 'Keywords') {
                $('#keywords_table').DataTable().column(2).search("", false, false).draw()
            } else if (table === 'Search Terms') {
                $('#search_terms_table').DataTable().column(1).search("", false, false).draw()
            }

            this.appliedGlobalSearchFilters.splice(idx, 1);
            this.applyGlobalSearchFilter();
        },
        removeAllGlobalFilters() {
            this.appliedGlobalFilters = [];
            this.appliedGlobalSearchFilters = [];

            switch (this.selectedTab) {
                case 'Campaigns':
                    this.getCampaignsTableData()
                    break;
                case 'ASINs':
                    this.getASINData()
                    break;
                case 'Keywords':
                    this.getKeywordsData()
                    break;
                case 'Search Terms':
                    this.getSearchTermsData()
                    break;
            }
        },
        removeGlobalFilter(index) {
            const section = this.appliedGlobalFilters[index].section
            const table = this.appliedGlobalFilters[index].table
            const metric = this.appliedGlobalFilters[index].metric
            if (this.appliedGlobalFilters[index].operand) {
                this.globalFilter[section][table][metric].selectedOption = "in between"
                this.globalFilter[section][table][metric].min = ""
                this.globalFilter[section][table][metric].max = ""
                this.globalFilter[section][table][metric].inputValue = ""
                this.appliedGlobalFilters.splice(index, 1)
            } else {
                this.globalFilter[section][table][metric].selectedOption = [];
                this.appliedGlobalFilters.splice(index, 1)
            }

            switch (this.selectedTab) {
                case 'Campaigns':
                    this.getCampaignsTableData()
                    break;
                case 'ASINs':
                    this.getASINData()
                    break;
                case 'Keywords':
                    this.getKeywordsData()
                    break;
                case 'Search Terms':
                    this.getSearchTermsData()
                    break;
            }
        },
        addGlobalFilter(section, table, metric) {
            if (this.globalFilter[section][table][metric].inputType === 'Number') {
                if (this.globalFilter[section][table][metric].selectedOption === 'in between') {
                    if (!this.globalFilter[section][table][metric].min) {
                        this.showErrorToast("Enter the min value");
                        return;
                    } else if (!this.globalFilter[section][table][metric].max) {
                        this.showErrorToast("Enter the max value")
                        return;
                    } else if (Number(this.globalFilter[section][table][metric].min) >= Number(this.globalFilter[section][table][metric].max)) {
                        this.showErrorToast("min value should be less than max value")
                        return;
                    } else {
                        /* Add to the filters array */
                        this.appliedGlobalFilters.push({
                            section: section,
                            table: table,
                            metric: metric,
                            operand: "in between",
                            min: Number(this.globalFilter[section][table][metric].min),
                            max: Number(this.globalFilter[section][table][metric].max)
                        })
                    }
                } else if (this.globalFilter[section][table][metric].selectedOption === 'greater than' || this.globalFilter[section][table][metric].selectedOption === 'less than') {
                    if (!this.globalFilter[section][table][metric].inputValue) {
                        this.showErrorToast("Enter the value to add filter")
                        return;
                    } else {
                        /* Add to the filters array */
                        this.appliedGlobalFilters.push({
                            section: section,
                            table: table,
                            metric: metric,
                            operand: this.globalFilter[section][table][metric].selectedOption,
                            value: Number(this.globalFilter[section][table][metric].inputValue)
                        })
                    }
                }
            } else if (this.globalFilter[section][table][metric].inputType === 'dropdown') {
                /* Handle if input type is dropdown */
                if (!this.globalFilter[section][table][metric].selectedOption.length) {
                    this.showErrorToast("Select some options to add filter");
                    return;
                } else {
                    this.appliedGlobalFilters.push({
                        section: section,
                        table: table,
                        metric: metric,
                        value: this.globalFilter[section][table][metric].selectedOption,
                    })
                }
            }
        },
        applyGlobalFilters() {
            if (!this.appliedGlobalFilters.length) {
                this.showErrorToast("Add filters to perform this action.");
                return
            }
            switch (this.selectedTab) {
                case 'Campaigns':
                    this.getCampaignsTableData()
                    break;
                case 'ASINs':
                    this.getASINData()
                    break;
                case 'Keywords':
                    this.getKeywordsData()
                    break;
                case 'Search Terms':
                    this.getSearchTermsData()
                    break;
            }
            this.isGlobalFilterOpen = false;
        },
        showErrorToast(msg) {
            this.$toast.error(msg, {
                position: "bottom-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        getUpdatedData(action = "") {
            if (action === "firstRender") {
                this.getCampaignsByQuadrant();
                this.getCampaignsTableData();
                this.movingAverageData();
                this.setTooltip();
            } else {
                this.getCampaignsByQuadrant("quadrantChange");
            }
        },
        redrawTable() {
            switch (this.selectedTab) {
                case 'Campaigns':
                    if (this.selectedNonCompareMetrices.length) {
                        $('#campaigns_table').DataTable().destroy();
                        this.campaignTable.header = structuredClone(this.selectedNonCompareMetrices)
                        this.campaignTable.header.unshift({ key: 'check', label: 'Select' })
                        this.$nextTick(() => {
                            this.processTable('#campaigns_table', 1, 'plotReady')
                        })
                    }
                    break;
                case 'ASINs':
                    if (this.selectedNonCompareMetrices.length) {
                        $('#asins_table').DataTable().destroy();
                        this.ASINTable.header = structuredClone(this.selectedNonCompareMetrices)
                        this.$nextTick(() => {
                            this.processTable('#asins_table', 1, 'plotReady')
                        })
                    }
                    break;
                case 'Keywords':
                    if (this.selectedNonCompareMetrices.length) {
                        $('#keywords_table').DataTable().destroy();
                        this.keywordsTable.header = structuredClone(this.selectedNonCompareMetrices)
                        this.keywordsTable.header.unshift({ key: 'check', label: 'Select' })
                        this.$nextTick(() => {
                            this.processTable('#keywords_table', 1, 'plotReady')
                        })
                    }
                    break;
                case 'Search Terms':
                    if (this.selectedNonCompareMetrices.length) {
                        $('#search_terms_table').DataTable().destroy();
                        this.searchTermsTable.header = structuredClone(this.selectedNonCompareMetrices)
                        this.$nextTick(() => {
                            this.processTable('#search_terms_table', 1, 'plotReady')
                        })
                    }
                    break;

                default:
                    break;
            }
        },
        applyMetricSelection() {
            if (this.metricesChange) {
                switch (this.selectedTab) {
                    case 'Campaigns':
                        this.getCampaignsTableData();
                        break;
                    case 'ASINs':
                        this.getASINData();
                        break;
                    case 'Keywords':
                        this.getKeywordsData();
                        break;
                    case 'Search Terms':
                        this.getSearchTermsData();
                        break;
                }
            } else return;
        },
        addInterval() {
            this.intervals.push({
                dateRange: {
                    startDate: moment().format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD"),
                }
            })
            this.$nextTick(() => {
                this.setDateRange();
            })
        },
        removeInterval(selectedIndex) {
            this.intervals.splice(selectedIndex, 1);
            this.$nextTick(() => {
                this.setDateRange();
            })
        },
        setDateRange() {
            let rangeMapper = [6, 29, 64, 89]
            this.intervals.forEach((interval, idx) => {
                interval.dateRange.startDate = moment().subtract(rangeMapper[idx], "days").format("YYYY-MM-DD");
                interval.dateRange.endDate = moment().format("YYYY-MM-DD")
            })
        },
        setTooltip() {
            this.intervals.forEach((interval, index) => {
                let key = `Interval ${index + 1}`;
                this.tooltip[key] = `${moment(interval.dateRange.startDate).format("MMM DD, YYYY")} - ${moment(interval.dateRange.endDate).format("MMM DD, YYYY")}`;
            })
        },
        close(event) {
            if ((event.target.id !== 'compare-button')) {
                if (event.target.matches('svg') || event.target.querySelector('svg') !== null || event.target.closest('svg')) return;
                else if (document.querySelector('#comparison-box').contains(event.target)) return;
                this.showComparison = false
            }
        },
        applyComparisonFilter() {
            this.applyComparison = true;
            this.getCampaignsTableData();
            switch (this.selectedTab) {
                case 'Campaigns':
                    this.getCampaignsTableData();
                    break;
                case 'ASINs':
                    this.getASINData();
                    break;
                case 'Keywords':
                    this.getKeywordsData();
                    break;
                case 'Search Terms':
                    this.getSearchTermsData();
                    break;
            }
            this.setTooltip()
            this.showComparison = false;
            this.metricesChange = false;
        },
        removeComparisonFilter() {
            this.applyComparison = false;
            switch (this.selectedTab) {
                case 'Campaigns':
                    this.getCampaignsTableData();
                    break;
                case 'ASINs':
                    this.getASINData();
                    break;
                case 'Keywords':
                    this.getKeywordsData();
                    break;
                case 'Search Terms':
                    this.getSearchTermsData();
                    break;
            }
            this.intervals = [
                {
                    dateRange: {
                        startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                    }
                },
                {
                    dateRange: {
                        startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                    }
                },
                {
                    dateRange: {
                        startDate: moment().subtract(64, "days").format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                    }
                }
            ],
                this.showComparison = false;
            this.metricesChange = false;
        },
        applyFilters() {
            if (this.campaignsChange) {
                this.drilledCampaignsList = [];
                this.drilledKeywordsList = [];
                this.selectedTab = "Campaigns"
                this.getCampaignsTableData();
                this.movingAverageData();
                this.$nextTick(() => {
                    this.campaignsChange = false;
                })
            } else return;
        },
        applyDateChange() {
            this.drilledCampaignsList = [];
            this.drilledKeywordsList = [];
            this.movingAverageData();
            switch (this.selectedTab) {
                case 'Campaigns':
                    this.getCampaignsTableData();
                    break;
                case 'ASINs':
                    this.getASINData();
                    break;
                case 'Keywords':
                    this.getKeywordsData();
                    break;
                case 'Search Terms':
                    this.getSearchTermsData();
                    break;
            }
        },
        getDrilledCampAnalysis(selectedRow, selectedIndex, action) {
            if (action === 'filter') {
                this.drilledCampaignsList = [];
                this.campaignTable.data.forEach(campRow => campRow.check = false);
                this.campaignTable.data[selectedIndex].check = true;
                this.drilledCampaignsList.push(selectedRow.Campaign);
                this.ASINTable.data = [];
                this.keywordsTable.data = [];
                this.searchTermsTable.data = [];
                this.getASINData();
                this.selectedTab = "ASINs"
            } else if (action === 'compFilter') {
                this.drilledCampaignsList = [];
                this.campaignTable.data.forEach(row => row[0] = false);
                this.campaignTable.data[selectedIndex][0] = true;
                this.drilledCampaignsList.push(selectedRow[1])
                this.ASINTable.data = [];
                this.keywordsTable.data = [];
                this.searchTermsTable.data = [];
                this.getASINData();
                this.selectedTab = "ASINs"
            } else if (action === 'compMultiple') {
                this.campaignTable.data[selectedIndex][0] = !this.campaignTable.data[selectedIndex][0];
                if (selectedRow[0]) {
                    this.drilledCampaignsList.push(selectedRow[1])
                    this.ASINTable.data = [];
                    this.keywordsTable.data = [];
                    this.searchTermsTable.data = [];
                } else {
                    let campIndex = this.drilledCampaignsList.indexOf(selectedRow[1]);
                    this.drilledCampaignsList.splice(campIndex, 1)
                    this.ASINTable.data = [];
                    this.keywordsTable.data = [];
                    this.searchTermsTable.data = [];
                }
            } else {
                // Toggle Campaigns Row Selection
                this.campaignTable.data[selectedIndex].check = !this.campaignTable.data[selectedIndex].check;
                if (selectedRow.check) {
                    this.drilledCampaignsList.push(selectedRow.Campaign);
                    this.ASINTable.data = [];
                    this.keywordsTable.data = [];
                    this.searchTermsTable.data = [];
                } else {
                    let campIndex = this.drilledCampaignsList.indexOf(selectedRow.Campaign);
                    this.drilledCampaignsList.splice(campIndex, 1)
                    this.ASINTable.data = [];
                    this.keywordsTable.data = [];
                    this.searchTermsTable.data = [];
                }
            }
            this.processMovingAvgData(this.selectedGraphOptions)
        },
        applyCampFilter() {

            this.getASINData();
            this.getKeywordsData();
            this.getSearchTermsData();
        },
        clearCampaignsDrillDown() {
            this.selectedTab = "Campaigns"
            this.drilledCampaignsList = [];
            if (this.campaignTable.compare) {
                this.campaignTable.data.forEach(rowData => rowData[0] = false);
            } else this.campaignTable.data.forEach(rowData => rowData.check = false);
            this.getCampaignsTableData();
        },
        getDrilledKeywordsAnalysis(selectedRow, selectedIndex, action) {
            if (action === 'filter') {
                this.drilledKeywordsList = [];
                this.keywordsTable.data.forEach(keywordCamp => keywordCamp.check = false);
                this.keywordsTable.data[selectedIndex].check = true;
                this.drilledKeywordsList.push({
                    campaign_name: selectedRow.Campaign,
                    keyword: selectedRow.Keyword,
                    match_type: selectedRow["Match Type"]
                });
                this.searchTermsTable.data = []
                this.applyKeywordFilter();
            } else if (action === 'compFilter') {
                this.drilledKeywordsList = [];
                this.keywordsTable.data.forEach(row => row[0] = false);
                this.keywordsTable.data[selectedIndex][0] = true;
                this.drilledKeywordsList.push({
                    campaign_name: selectedRow[1],
                    keyword: selectedRow[2],
                    match_type: selectedRow[3]
                })
                this.searchTermsTable.data = []
                this.applyKeywordFilter()
            } else if (action === 'compMultiple') {
                this.keywordsTable.data[selectedIndex][0] = !this.keywordsTable.data[selectedIndex][0];
                if (selectedRow[0]) {
                    this.drilledKeywordsList.push({
                        campaign_name: selectedRow[1],
                        keyword: selectedRow[2],
                        match_type: selectedRow[3]
                    })
                    this.searchTermsTable.data = []
                } else {
                    let campIndex = this.drilledKeywordsList.indexOf(selectedRow[1]);
                    this.drilledKeywordsList.splice(campIndex, 1)
                    this.searchTermsTable.data = []
                }
            } else {
                this.keywordsTable.data[selectedIndex].check = !this.keywordsTable.data[selectedIndex].check;
                if (selectedRow.check) {
                    this.drilledKeywordsList.push({
                        campaign_name: selectedRow.Campaign,
                        keyword: selectedRow.Keyword,
                        match_type: selectedRow["Match Type"]
                    })
                } else {
                    let campIndex = this.drilledKeywordsList.findIndex(keywordRow => keywordRow.campaign_name === selectedRow.Campaign);
                    this.drilledKeywordsList.splice(campIndex, 1);
                }
            }
        },
        applyKeywordFilter() {
            this.getSearchTermsData();
            this.selectedTab = "Search Terms"
        },
        clearKeywordDrilldown() {
            this.selectedTab = "Keywords";
            this.drilledKeywordsList = [];
            if (this.keywordsTable.compare) {
                this.keywordsTable.data.forEach(rowData => rowData[0] = false);
            } else this.keywordsTable.data.forEach(rowData => rowData.check = false);
            this.getSearchTermsData();
        },
        backToAMS() {
            this.$router.push("ams");
        },
        getGlobalFilter() {
            axios.get('/api/amazon/filter/meta_data').then(res => {
                this.globalFilter = res.data.data;
            }).catch(err => {
                console.log(`Error in getting global filter::${err}`)
            })
        },
        getCampaignsByQuadrant(action = "") {
            this.loader = true;
            const url = "/api/amazon/quadrant/campagins_name";
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
            };

            let adType = [];

            if (this.activeCampaign) payload = { ...payload, camp_state: "Enabled" };
            else payload = { ...payload, camp_state: "All" };

            if (!this.selectedCampaignTypeData.length) {
                adType = []
            } else {
                this.selectedCampaignTypeData.forEach(element => {
                    adType.push(element.key)
                });
            }
            payload = { ...payload, ad_type: adType }

            axios.post(url, payload).then((res) => {
                this.campaignNamesList = res.data.data;
                this.selectedCampaigns.forEach(camp => {
                    let isCampPresent = false;
                    this.campaignNamesList.forEach(group => {
                        if (group.options.includes(camp)) {
                            isCampPresent = true;
                        }
                    })
                    if (!isCampPresent) {
                        const idx = this.selectedCampaigns.indexOf(camp);
                        this.selectedCampaigns.splice(idx, 1)
                    }
                });
                if (!this.selectedCampaigns.length) {
                    this.$toast.error("Please select a campaign to get data.", {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                }
                if (action === 'quadrantChange') {
                    this.getCampaignsTableData();
                    this.movingAverageData();
                    this.setTooltip();
                }
                if (action === 'dateChange') {
                    this.applyDateChange();
                }
                this.loader = false;
            }).catch((err) => {
                console.log(err);
                this.loader = false;
            });
        },
        getCampaignsTableData() {
            $('#campaigns_table').DataTable().destroy()
            $('#campaigns_comp_table').DataTable().destroy()
            this.loader1 = true;
            this.campaignTable.header = [];
            this.campaignTable.subHeader = [];
            this.campaignTable.data = [];
            this.campaignTable.rawData = [];
            this.nonCompareMetrices = [];
            this.selectedNonCompareMetrices = [];

            if (!this.selectedCampaigns.length) {
                this.loader1 = false;
                return;
            }

            const url = "/api/amazon/campains/data";

            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                campaign_name_list: this.selectedCampaigns,
                compare_dates: {},
                metrics: this.selectedMetrices,
            };

            if (this.applyComparison) {
                this.intervals.map((interval, idx) => {
                    let key = `interval_${idx + 1}`
                    payload["compare_dates"] = {
                        ...payload["compare_dates"],
                        [key]: {
                            start_date: moment(interval.dateRange.startDate).format("YYYY-MM-DD"),
                            end_date: moment(interval.dateRange.endDate).format("YYYY-MM-DD")
                        }
                    }
                })
            }

            if (this.appliedGlobalFilters.length) {
                let filterQuery = []
                this.appliedGlobalFilters.forEach(filter => {
                    if (filter.table === 'Campaign') {
                        if (filterQuery.length) {
                            let index = -1
                            filterQuery.forEach((insertedQuery, idx) => {
                                if (insertedQuery.metrics === filter.metric) {
                                    index = idx;
                                }
                            })
                            if (index === -1) {
                                filterQuery.push({
                                    metrics: filter.metric,
                                    operations_list: [
                                        {
                                            operand: filter.operand,
                                            value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                        }
                                    ]
                                })
                            } else {
                                filterQuery[index].operations_list.push({
                                    operand: filter.operand,
                                    value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                })
                            }
                        } else if (!filterQuery.length) {
                            filterQuery.push({
                                metrics: filter.metric,
                                operations_list: [
                                    {
                                        operand: filter.operand,
                                        value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                    }
                                ]
                            })
                        }
                    }
                })
                payload = {
                    ...payload,
                    global_filter: filterQuery
                }
            }

            axios.post(url, payload).then((res) => {
                this.campaignTable.compare = res.data.data.comparison
                if (!res.data.data.data) {
                    this.loader1 = false;
                    return;
                }
                if (!res.data.data.comparison) {
                    this.campaignTable.header = res.data.data.headers;
                    this.campaignTable.data = res.data.data.data;
                    this.nonCompareMetrices = res.data.data.headers.filter(header => header.key !== 'check');
                    this.updateMetricsHeader();
                    this.selectedNonCompareMetrices = structuredClone(this.nonCompareMetrices);
                    if (this.drilledCampaignsList.length) {
                        this.drilledCampaignsList.forEach(camp => {
                            this.campaignTable.data.forEach(fetchedCamp => {
                                if (camp === fetchedCamp.Campaign) fetchedCamp.check = true
                            })
                        })
                    }
                } else this.campaignTable.rawData = res.data.data.data;

                if (this.campaignTable.compare) {
                    for (let subrows of Object.values(res.data.data.data[0])) {
                        if (typeof (subrows) === 'object') {
                            Object.keys(subrows).forEach(key => this.campaignTable.subHeader.push(key))
                        }
                    }
                    for (let rows of res.data.data.data) {
                        if (this.drilledCampaignsList.length) {
                            this.drilledCampaignsList.forEach(camp => {
                                if (rows.Campaign === camp) rows.select = true;
                            })
                        }
                        let tempContainer = []
                        for (let rowVal of Object.values(rows)) {
                            if (typeof (rowVal) === 'string' || typeof (rowVal) === 'boolean') {
                                tempContainer.push(rowVal)
                            }
                            if (typeof (rowVal) === 'object') {
                                for (let innData of Object.values(rowVal)) {
                                    tempContainer.push(innData)
                                }
                            }
                        }
                        this.campaignTable.data.push(tempContainer)
                        tempContainer = []
                    }
                }
                this.metricesChange = false;
                this.loader1 = false;
                this.generateMetricsForFilter();
                this.$nextTick(() => {
                    if (this.campaignTable.compare) this.processTable('#campaigns_comp_table', 1);
                    else this.processTable('#campaigns_table', 1, "")
                    this.applyGlobalSearchFilter();
                })
            }).catch((err) => {
                console.log("error", err);
                this.loader1 = false;
            });
        },
        getASINData() {
            $('#asins_table').DataTable().destroy()
            $('#asins_comp_table').DataTable().destroy()
            this.ASINTable.data = [];
            this.ASINTable.header = [];
            this.ASINTable.subHeader = [];
            this.ASINTable.rawData = [];
            this.nonCompareMetrices = [];
            this.selectedNonCompareMetrices = [];
            this.loader2 = true;

            if (!this.selectedCampaigns.length) {
                this.loader2 = false;
                return;
            }

            const url = "/api/amazon/quadrant/advertised_data";
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                campaign_name_list: this.selectedCampaigns,
                compare_dates: [],
                metrics: this.selectedMetrices
            }

            if (this.drilledCampaignsList.length) payload['campaign_name_list'] = this.drilledCampaignsList;

            if (this.applyComparison) {
                this.intervals.map((interval, idx) => {
                    let key = `interval_${idx + 1}`
                    payload["compare_dates"] = {
                        ...payload["compare_dates"],
                        [key]: {
                            start_date: moment(interval.dateRange.startDate).format("YYYY-MM-DD"),
                            end_date: moment(interval.dateRange.endDate).format("YYYY-MM-DD")
                        }
                    }
                })
            }

            if (this.appliedGlobalFilters.length) {
                let filterQuery = []
                this.appliedGlobalFilters.forEach(filter => {
                    if (filter.table === 'AdProducts') {
                        if (filterQuery.length) {
                            let index = -1
                            filterQuery.forEach((insertedQuery, idx) => {
                                if (insertedQuery.metrics === filter.metric) {
                                    index = idx;
                                }
                            })
                            if (index === -1) {
                                filterQuery.push({
                                    metrics: filter.metric,
                                    operations_list: [
                                        {
                                            operand: filter.operand,
                                            value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                        }
                                    ]
                                })
                            } else {
                                filterQuery[index].operations_list.push({
                                    operand: filter.operand,
                                    value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                })
                            }
                        } else if (!filterQuery.length) {
                            filterQuery.push({
                                metrics: filter.metric,
                                operations_list: [
                                    {
                                        operand: filter.operand,
                                        value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                    }
                                ]
                            })
                        }
                    }
                })
                payload = {
                    ...payload,
                    global_filter: filterQuery
                }
            }

            axios.post(url, payload).then(res => {
                this.ASINTable.compare = res.data.data.comparison
                if (!res.data.data.data) {
                    this.loader2 = false;
                    return;
                }
                if (!res.data.data.comparison) {
                    this.ASINTable.header = res.data.data.headers.filter(header => header.key !== 'check');
                    this.ASINTable.data = res.data.data.data;
                    this.nonCompareMetrices = res.data.data.headers.filter(header => header.key !== 'check');
                    this.selectedNonCompareMetrices = structuredClone(this.nonCompareMetrices);
                    this.updateMetricsHeader();
                } else this.ASINTable.rawData = res.data.data.data;

                if (this.ASINTable.compare) {
                    for (let subrows of Object.values(res.data.data.data[0])) {
                        if (typeof (subrows) === 'object') {
                            Object.keys(subrows).forEach(key => this.ASINTable.subHeader.push(key))
                        }
                    }
                    for (let rows of res.data.data.data) {
                        let tempContainer = []
                        for (let rowVal of Object.values(rows)) {
                            if (typeof (rowVal) === 'string') {
                                tempContainer.push(rowVal)
                            }
                            if (typeof (rowVal) === 'object') {
                                for (let innData of Object.values(rowVal)) {
                                    tempContainer.push(innData)
                                }
                            }
                        }
                        this.ASINTable.data.push(tempContainer)
                        tempContainer = []
                    }
                }
                this.metricesChange = false;
                this.loader2 = false;
                this.$nextTick(() => {
                    if (this.ASINTable.compare) this.processTable('#asins_comp_table', 1);
                    else this.processTable('#asins_table', 1, "");
                    this.applyGlobalSearchFilter();
                })
            }).catch(err => {
                console.log(err);
                this.loader2 = false;
            })
        },
        getKeywordsData() {
            $('#keywords_table').DataTable().destroy()
            $('#keywords_comp_table').DataTable().destroy()
            this.keywordsTable.header = [];
            this.keywordsTable.data = [];
            this.keywordsTable.subHeader = [];
            this.keywordsTable.rawData = [];
            this.nonCompareMetrices = [];
            this.selectedNonCompareMetrices = [];
            this.loader3 = true;

            if (!this.selectedCampaigns.length) {
                this.loader3 = false;
                return;
            }


            const url = "/api/amazon/quadrant/keyword_data";
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                campaign_name_list: this.selectedCampaigns,
                compare_dates: [],
                metrics: this.selectedMetrices
            }

            if (this.keywordFilterMatchType) payload = { ...payload, search_keyword: this.keywordFilterMatchType }
            if (this.drilledCampaignsList.length) payload['campaign_name_list'] = this.drilledCampaignsList;

            if (this.applyComparison) {
                this.intervals.map((interval, idx) => {
                    let key = `interval_${idx + 1}`
                    payload["compare_dates"] = {
                        ...payload["compare_dates"],
                        [key]: {
                            start_date: moment(interval.dateRange.startDate).format("YYYY-MM-DD"),
                            end_date: moment(interval.dateRange.endDate).format("YYYY-MM-DD")
                        }
                    }
                })
            }

            if (this.appliedGlobalFilters.length) {
                let filterQuery = []
                this.appliedGlobalFilters.forEach(filter => {
                    if (filter.table === 'Keywords') {
                        if (filterQuery.length) {
                            let index = -1
                            filterQuery.forEach((insertedQuery, idx) => {
                                if (insertedQuery.metrics === filter.metric) {
                                    index = idx;
                                }
                            })
                            if (index === -1) {
                                filterQuery.push({
                                    metrics: filter.metric,
                                    operations_list: [
                                        {
                                            operand: filter.operand,
                                            value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                        }
                                    ]
                                })
                            } else {
                                filterQuery[index].operations_list.push({
                                    operand: filter.operand,
                                    value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                })
                            }
                        } else if (!filterQuery.length) {
                            filterQuery.push({
                                metrics: filter.metric,
                                operations_list: [
                                    {
                                        operand: filter.operand,
                                        value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                    }
                                ]
                            })
                        }
                    }
                })
                payload = {
                    ...payload,
                    global_filter: filterQuery
                }
            }

            axios.post(url, payload).then(res => {
                this.keywordsTable.compare = res.data.data.comparison;
                if (!res.data.data.data) {
                    this.loader3 = false;
                    return;
                }
                var campCount = 0
                if (!res.data.data.comparison) {
                    this.keywordsTable.header = res.data.data.headers
                    this.keywordsTable.data = res.data.data.data;
                    this.nonCompareMetrices = res.data.data.headers.filter(header => header.key !== 'check');
                    this.updateMetricsHeader();
                    this.selectedNonCompareMetrices = structuredClone(this.nonCompareMetrices);
                    if (this.drilledKeywordsList.length) {
                        this.drilledKeywordsList.forEach(camp => {
                            this.keywordsTable.data.forEach(fetchedCamp => {
                                if (camp.keyword === fetchedCamp.Keyword && camp.match_type === fetchedCamp['Match Type'] && camp.campaign_name === fetchedCamp.Campaign) {
                                    fetchedCamp.check = true
                                    campCount++;
                                };
                            })
                        })
                    }
                    if (campCount === 0) this.drilledKeywordsList = [];
                } else {
                    this.keywordsTable.rawData = res.data.data.data;
                }
                if (this.keywordsTable.compare) {
                    for (let subrows of Object.values(res.data.data.data[0])) {
                        if (typeof (subrows) === 'object') {
                            Object.keys(subrows).forEach(key => this.keywordsTable.subHeader.push(key))
                        }
                    }

                    for (let rows of res.data.data.data) {
                        if (this.drilledKeywordsList.length) {
                            this.drilledKeywordsList.forEach(camp => {
                                if (rows.Keyword === camp.keyword && rows['Match Type'] === camp.match_type && rows.Campaign === camp.campaign_name) {
                                    rows.select = true;
                                    campCount++;
                                }
                            })
                        }
                        let tempContainer = []
                        for (let rowVal of Object.values(rows)) {
                            if (typeof (rowVal) === 'string' || typeof (rowVal) === 'boolean') {
                                tempContainer.push(rowVal)
                            }
                            if (typeof (rowVal) === 'object') {
                                for (let innData of Object.values(rowVal)) {
                                    tempContainer.push(innData)
                                }
                            }
                        }
                        this.keywordsTable.data.push(tempContainer)
                        tempContainer = []
                    }
                    if (campCount === 0) this.drilledKeywordsList = [];
                }
                this.metricesChange = false;
                this.loader3 = false;
                this.$nextTick(() => {
                    if (this.keywordsTable.compare) this.processTable('#keywords_comp_table', 1);
                    else this.processTable('#keywords_table', 1, "")
                    this.applyGlobalSearchFilter();
                })
            }).catch(err => {
                console.log(err);
                this.loader3 = false;
            })
        },
        getSearchTermsData() {
            $('#search_terms_table').DataTable().destroy()
            $('#search_terms_comp_table').DataTable().destroy()
            this.searchTermsTable.header = [];
            this.searchTermsTable.data = [];
            this.searchTermsTable.subHeader = [];
            this.searchTermsTable.rawData = [];
            this.nonCompareMetrices = [];
            this.selectedNonCompareMetrices = [];
            this.loader4 = true;

            if (!this.selectedCampaigns.length) {
                this.loader4 = false;
                return;
            }

            const url = "/api/amazon/quadrant/search_data";
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                compare_dates: [],
                metrics: this.selectedMetrices
            }

            if (this.searchTermFilterMatchType) payload = { ...payload, search_keyword: this.searchTermFilterMatchType }
            if (this.drilledKeywordsList.length) {
                payload = {
                    ...payload,
                    query_list: this.drilledKeywordsList
                }
            } else if (this.drilledCampaignsList.length) {
                payload = {
                    ...payload,
                    campaign_name_list: this.drilledCampaignsList
                };
            } else {
                payload = {
                    ...payload,
                    campaign_name_list: this.selectedCampaigns
                }
            }

            if (this.applyComparison) {
                this.intervals.map((interval, idx) => {
                    let key = `interval_${idx + 1}`
                    payload["compare_dates"] = {
                        ...payload["compare_dates"],
                        [key]: {
                            start_date: moment(interval.dateRange.startDate).format("YYYY-MM-DD"),
                            end_date: moment(interval.dateRange.endDate).format("YYYY-MM-DD")
                        }
                    }
                })
            }

            if (this.appliedGlobalFilters.length) {
                let filterQuery = []
                this.appliedGlobalFilters.forEach(filter => {
                    if (filter.table === 'Search Terms') {
                        if (filterQuery.length) {
                            let index = -1
                            filterQuery.forEach((insertedQuery, idx) => {
                                if (insertedQuery.metrics === filter.metric) {
                                    index = idx;
                                }
                            })
                            if (index === -1) {
                                filterQuery.push({
                                    metrics: filter.metric,
                                    operations_list: [
                                        {
                                            operand: filter.operand,
                                            value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                        }
                                    ]
                                })
                            } else {
                                filterQuery[index].operations_list.push({
                                    operand: filter.operand,
                                    value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                })
                            }
                        } else if (!filterQuery.length) {
                            filterQuery.push({
                                metrics: filter.metric,
                                operations_list: [
                                    {
                                        operand: filter.operand,
                                        value: filter.operand === 'in between' ? [filter.min, filter.max].toString() : filter.value
                                    }
                                ]
                            })
                        }
                    }
                })
                payload = {
                    ...payload,
                    global_filter: filterQuery
                }
            }

            axios.post(url, payload).then(res => {
                this.searchTermsTable.compare = res.data.data.comparison
                if (!res.data.data.data) {
                    this.loader4 = false;
                    return;
                }
                if (!res.data.data.comparison) {
                    this.searchTermsTable.header = res.data.data.headers.filter(header => header.key !== 'check');
                    this.searchTermsTable.data = res.data.data.data;
                    this.nonCompareMetrices = res.data.data.headers.filter(header => header.key !== 'check');
                    this.selectedNonCompareMetrices = structuredClone(this.nonCompareMetrices);
                    this.updateMetricsHeader();
                } else this.searchTermsTable.rawData = res.data.data.data;

                if (this.searchTermsTable.compare) {
                    for (let subrows of Object.values(res.data.data.data[0])) {
                        if (typeof (subrows) === 'object') {
                            Object.keys(subrows).forEach(key => this.searchTermsTable.subHeader.push(key))
                        }
                    }
                    for (let rows of res.data.data.data) {
                        let tempContainer = []
                        for (let rowVal of Object.values(rows)) {
                            if (typeof (rowVal) === 'string') {
                                tempContainer.push(rowVal)
                            }
                            if (typeof (rowVal) === 'object') {
                                for (let innData of Object.values(rowVal)) {
                                    tempContainer.push(innData)
                                }
                            }
                        }
                        this.searchTermsTable.data.push(tempContainer)
                        tempContainer = []
                    }
                }
                this.metricesChange = false;
                this.loader4 = false;
                this.$nextTick(() => {
                    if (this.searchTermsTable.compare) this.processTable('#search_terms_comp_table', 1);
                    else this.processTable('#search_terms_table', 1, "")
                    this.applyGlobalSearchFilter();
                })
            }).catch(err => {
                console.log(err);
                this.loader4 = false;
            })
        },
        movingAverageData() {
            this.MovingAvgGraphData = []
            this.loader5 = true
            this.moviginAvgData = ''
            if (!this.selectedCampaigns.length) {
                this.loader5 = false;
                return;
            }

            let url = "/api/amazon/campaign/graph "
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                campaign_name_list: this.selectedCampaigns,
                interval: this.selectedMovAvgData
            }
            axios.post(url, payload).then(res => {
                if (!Object.keys(res.data.data).length) {
                    this.loader5 = false;
                    return;
                }
                this.moviginAvgData = res.data.data
                this.processMovingAvgData(this.selectedGraphOptions);
                this.loader5 = false
            }).catch(err => {
                console.log(err)
                this.loader5 = false
            })
        },
        exportTable(tableName) {
            this.loader = true;

            const dataTypeMapper = {
                campaignTable: "Campaign",
                ASINTable: "Ad Products",
                keywordsTable: "Keywords",
                searchTermsTable: "Search Terms"
            }

            const url = "/api/amazon/optimization/excel";
            let payload;

            if (this[tableName].compare) {
                payload = {
                    data: this[tableName].rawData,
                    comparison: this[tableName].compare,
                    data_type: dataTypeMapper[tableName],
                    metrics: this.selectedMetrices,
                    Intervals: {}
                }
                Object.keys(this.tooltip).forEach(key => {
                    if (this.tooltip[key] !== null) {
                        payload.Intervals = {
                            ...payload.Intervals,
                            [key]: {
                                start_date: moment(this.tooltip[key].split(" - ")[0]).format("YYYY-MM-DD"),
                                end_date: moment(this.tooltip[key].split(" - ")[1]).format("YYYY-MM-DD")
                            }
                        }
                    }
                })
            } else {
                payload = {
                    data: this[tableName].data,
                    comparison: this[tableName].compare,
                    data_type: dataTypeMapper[tableName]
                }
            }

            axios.post(url, payload).then(res => {
                window.location = res.data.data
                this.loader = false;
            }).catch(err => {
                console.log(`Error in exporting table::${err}`);
                this.loader = false;
            })
        },
        processMovingAvgData(selectedGraph) {
            this.selectedGraphOptions = selectedGraph
            let data;
            let tempData = structuredClone(this.moviginAvgData)
            if (this.drilledCampaignsList.length) {
                Object.keys(tempData).forEach(metricKey => {
                    tempData[metricKey].forEach(series => {
                        Object.keys(series.campaigns).forEach(campName => {
                            if (!this.drilledCampaignsList.includes(campName)) {
                                delete series.campaigns[campName]
                            }
                        })
                    })
                })

                data = tempData;
            } else {
                data = this.moviginAvgData
            }
            // console.log("checking data", data)
            this.MovingAvgGraphData = [];
            let tempSeriesData = {}
            this.graphDropdown = Object.keys(data)
            tempSeriesData = {
                xAxis: [],
                name: selectedGraph,
                legend: true,
                legendPosition: 'bottom',
                legendMargin: 60,
                ylabel: '',
                seriesData: [
                ]
            }

            const campaigns = data[selectedGraph][0].campaigns;
            const campLength = Object.keys(campaigns).length;

            for (let i = 1; i <= campLength; i++) {
                tempSeriesData.seriesData.push({
                    prefix: '',
                    suffix: '',
                    name: '',
                    data: []
                })
            }

            for (let cat of data[selectedGraph]) {
                Object.keys(cat.campaigns).forEach((key, index) => {
                    tempSeriesData.seriesData.forEach((obj, idx) => {
                        if (index === idx) {
                            tempSeriesData.seriesData[idx].name = key
                            tempSeriesData.seriesData[idx].prefix = cat.prefix
                            tempSeriesData.seriesData[idx].suffix = cat.suffix
                            tempSeriesData.seriesData[idx].data.push(cat.campaigns[key])
                        }
                    })
                })

                tempSeriesData.xAxis.push(cat.date);
            }
            this.MovingAvgGraphData.push(tempSeriesData);
        },
        changeGSDuration(duration) {
            this.selectedMovAvgData = duration
            this.movingAverageData()
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },
        processTable(id, sortCol = 0, state = "") {
            if (state !== 'plotReady') $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                // lengthChange: false,
                sorting: [[sortCol, "desc"]],
                initComplete: function (settings, json) {
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",

                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_",
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },
    },
};