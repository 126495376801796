<template lang="pug">
div(id="app")
  div(v-if="!isLoginPage && !floatingNav" class="flex")
    Sidebar(@toggle-sidebar="onToggleSidebar" :class="sidebarOpenStatus ? 'sidebarOpen' : 'sidebarClose'") 
    div(:class="sidebarOpenStatus ? 'bodyNarrow' : 'bodyWide'")
      Header(class="w-full")
      div
        router-view(:key="$route.path")
  div(v-else-if="!isLoginPage && floatingNav")
    Sidebar(@toggle-sidebar="onToggleSidebar" class="left-0 -mt-1"
      :class="sidebarOpenStatus ? 'w-3/5 sm:w-1/3 md:w-1/4' : 'w-0'" id="sidebar")
    div(class="absolute top-0" id="content" @touchstart="handleSidebarClose($event)")
      Header(class="w-screen" @open-sidebar="onToggleSidebar")
      div(class="w-screen")
        router-view(:key="$route.path")
  div(v-else)
    router-view(:key="$route.path") 
</template>
<script>
import axios from 'axios'
import jwt_decode from "jwt-decode";
import Header from './components/Header/Header.vue'
import Sidebar from './components/Sidebar/Sidebar.vue'
import Privacy from './pages/Privacy/Privacy.vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import moment from 'moment'
import router from './router';

export default {
  name: 'App',
  components: { Header, Sidebar, Privacy, router },
  data() {
    return {
      isSidebarOpen: true,
      isLoginPage: true,
      isPrivacyPage: false,
      isRegisterPage: false,
      sidebarOpenStatus: true,
      isRecoveryPage: false,
      resetToken: this.$route.params.id,
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  computed: {
    floatingNav() {
      if (window.screen.width < 1024) {
        return true;
      } else return;
    }
  },
  watch: {
    '$route': function (to, from) {
      this.checkPage()
      this.checkLoggedIn()
    },
    sidebarOpenStatus() {
      if(this.floatingNav){
        if(this.sidebarOpenStatus){
          document.querySelector('body').style.overflow = 'hidden'
        } else document.querySelector('body').style.overflow = '';
      }
    }
  },
  created() {

    if (this.floatingNav) {
      this.sidebarOpenStatus = false;
      this.isSidebarOpen = false;
    }
    this.checkPage();
    this.checkLoggedIn();

    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    handleSidebarClose(event) {
      const sidebar = document.getElementById('sidebar'); // Reference to the sidebar element
      const content = document.getElementById('content'); // Reference to the content element

      const sidebarRect = sidebar.getBoundingClientRect();
      const contentRect = content.getBoundingClientRect();

      const touch = event.touches[0]; // Get the first touch event

      // Check if the touch position is within the sidebar boundaries
      if (
        touch.clientX >= sidebarRect.left &&
        touch.clientX <= sidebarRect.right &&
        touch.clientY >= sidebarRect.top &&
        touch.clientY <= sidebarRect.bottom
      ) {
        this.sidebarOpenStatus = true;
      } else {
        this.sidebarOpenStatus = false;
      }
    },
    showRefreshUI(e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.updateExists = true;
      const infoShown = new Promise(this.$toast.info("Newer version of app is detected, refreshing!", {
        position: "bottom-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      })).then(res => this.refreshApp());
      
    },
    refreshApp() {
      // Handle a user tap on the update app button.
      this.updateExists = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
    checkPage() {
      let currentPath = this.$router.history.current.path
      if (currentPath == '/login' || currentPath == '/privacy') {
        this.isLoginPage = true
        this.isPrivacyPage = true
      }
      else if (currentPath == '/login' || currentPath == '/Register') {
        this.isLoginPage = true
        this.isRecoveryPage = true
      }
      else if (currentPath == '/login' || currentPath == "/recovery/" + this.resetToken) {
        this.isLoginPage = true
        this.isRecoveryPage = true
      }
      else {
        this.isLoginPage = false
        this.isPrivacyPage = false
        this.isRegisterPage = false
        this.isRecoveryPage = false
      }
    },
    decodeHTML(html) {
      let txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value; // decoder for HTML-encoded strings.
    },
    onToggleSidebar(isSidebarOpen) {
      if (localStorage.sidebarOpen === false) {
        this.sidebarOpenStatus = false
      } else this.sidebarOpenStatus = isSidebarOpen
    },
    checkLoggedIn() {
      let currentPath = this.$router.history.current.path
      if (Object.keys(localStorage).includes('userData')) {
        let decode = jwt_decode(JSON.parse(localStorage.userData).data.token);
        let tokenTime = moment(decode.target_time)
        let currentTime = moment()
        if (currentTime < tokenTime) {
          let token = JSON.parse(localStorage.userData).data.token
          axios.defaults.headers.common['token'] = token
          if (currentPath == "/app" || currentPath == "/login" || currentPath == "/") {
            this.$nextTick(() => {
              this.$router.push(this.$store.state.menu[0].submenuItems[0].link)
              localStorage.setItem('selectedMenu', 0)
            });
          }
        }
        else {
          if ((currentPath != "/login") && (currentPath != "/privacy") && (currentPath != "/Register") && (currentPath != "/recovery/" + this.resetToken)) {
            this.$store.dispatch("store/unsetMenu")
            localStorage.removeItem('selectedMenu')
            this.$router.push("/login");
          }
        }
      }
      else {
        if ((currentPath != "/login") && (currentPath != "/privacy") && (currentPath != "/Register") && (currentPath != "/recovery/" + this.resetToken)) {
          this.$store.dispatch("store/unsetMenu")
          localStorage.removeItem('selectedMenu')
          this.$router.push("/login");
        }
      }
    }
  }
}
</script>
<style lang="sass" src="./styles/Style.sass"/>
<style lang="sass" src="./styles/DataTable.sass"></style>
<style lang="sass" src="./styles/MultiSelect.sass"></style>

