<template lang="pug">
div.w-card.m-3
    div(class="text-sm ml-1 mb-1 text-[#1e1e1e]") Date Range
    date-range-picker( v-model="dateRange" opens="right" :show-dropdowns="true" :locale-data="{format:'dd mmm yyyy'}")
        div(slot="footer" slot-scope="data" class="flex justify-between items-center border-[1px] border-t-primary_brand")
            div(class="flex gap-4 justify-between items-center p-2")
                label(for="compare-toggle" class="text-primary_brand text-xs") Compare
                label( id="compare-toggle" class=" relative inline-flex items-center mr-1 cursor-pointer")
                    input(type="checkbox" :value="showComp" class="sr-only peer" v-model="showComp")
                    div(class="w-8 h-4 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0 after:left-0 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary_brand-80")
            div(class="flex gap-3 justify-end items-center")
                div(class="flex gap-2 items-center" id="compare-date-range" v-show="showComp")
                    date-range-picker(:auto-apply="true" v-model="compDateRange" opens="right" :show-dropdowns="true")
                    div.w-60.customMultiselect(class="pt-[3px] mb-0 pb-0")
                        multiselect(
                            v-model="selectedCompRange"
                            :options='["Custom", "Previous Period", "Previous Year"]'
                            :searchable="false"
                            :show-labels="false" 
                            :multiple="false"
                            :allow-empty="true"
                            open-direction="bottom"
                            placeholder="Select"
                        )
                div
                    a(@click="data.clickApply" v-if="!data.in_selection" class="px-1 py-1.5 mr-1 rounded bg-primary_brand hover:bg-primary_brand-80 text-white text-xs cursor-pointer") Apply
</template>
<script>
import moment from 'moment';
export default {
    name: "Demo",
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(15, 'days').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            compDateRange: {
                startDate: moment().subtract(45, 'days').format("YYYY-MM-DD"),
                endDate: moment().subtract(30, 'days').format("YYYY-MM-DD")
            },
            showComp: false,
            selectedCompRange: "",

        }
    },
    updated() {
        console.log("date range", this.dateRange)
        console.log("comp date range", this.compDateRange)
    }
}
</script>
<style>
#compare-date-range>.vue-daterange-picker>.daterangepicker.ltr.show-calendar.show-ranges.opensright.linked {
    left: -176px !important;
}

#compare-date-range>.vue-daterange-picker>.daterangepicker.ltr.show-calendar.show-ranges.opensleft.linked {
    right: -308px !important;
}

#compare-date-range>.vue-daterange-picker>.daterangepicker::after {
    display: none !important;
}

#compare-date-range>.vue-daterange-picker>.daterangepicker::before {
    display: none !important;
}

/* .vue-daterange-picker>.daterangepicker {
    left: 10px !important;
} */
</style>